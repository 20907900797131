.ui.form input:not([type]):focus,
.ui.form input[type=date]:focus,
.ui.form input[type=datetime-local]:focus,
.ui.form input[type=email]:focus,
.ui.form input[type=file]:focus,
.ui.form input[type=number]:focus,
.ui.form input[type=password]:focus,
.ui.form input[type=search]:focus,
.ui.form input[type=tel]:focus,
.ui.form input[type=text]:focus,
.ui.form input[type=time]:focus,
.ui.form input[type=url]:focus,
.ui.selection.dropdown:focus,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu,
.ui.selection.active.dropdown .menu,
.ui.form textarea:focus {
  border-color: var(--form-input-border-color-focus);
  border-radius: var(--button-border-radius);
}
.ui.selection.active.dropdown:hover .menu,
.ui.selection.active.dropdown .menu {
  width: calc(100% + 4px);
  margin-left: -2px;
  border: var(--form-input-border);
  border-color: var(--form-input-border-color-focus);
  box-shadow: var(--box-shadow);
}
.ui.upward.selection.dropdown .menu {
  border-top-width: 2px !important;
}
.ui.selection.dropdown .visible.menu.transition {
  border-radius: 0 0 var(--button-border-radius) var(--button-border-radius);
}
.ui.upward.selection.dropdown.visible {
  border-radius: 0 0 var(--button-border-radius) var(--button-border-radius) !important;
}
.ui.selection.dropdown.upward .visible.menu.transition {
  border-radius: var(--button-border-radius) var(--button-border-radius) 0 0;
}
.ui.selection.dropdown .menu>.item {
  border-top: none;
}
.ui.selection.dropdown,
.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url],
.ui.form textarea {
  border: var(--form-input-border);
  border-radius: var(--button-border-radius);
}
.ui.form .field {
  margin: 0 0 1.5em;
}
.ui.form .field > label {
  display: block;
  font-weight: 700;
  font-size: 1.2em;
  padding-bottom: 0.3em;
  font-family: var(--theme-font-family);
  color: var(--color-text-black);
}
.ui.form .field > label:not(:first-child) {
  padding-top: 1.5em;
}
