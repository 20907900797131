/* HankRnd */
@font-face {
  font-family: 'HankRnd';
  src: url("./theme-fonts/HankRnd-Black.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HankRnd';
  src: url("./theme-fonts/HankRnd-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HankRnd';
  src: url("./theme-fonts/HankRnd-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HankRnd';
  src: url("./theme-fonts/HankRnd-Bold.ttf") format('truetype');
  font-weight: bold;
  font-style: normal;
}


/* Poppins */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("./theme-fonts/Poppins-ThinItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("./theme-fonts/Poppins-ExtraLightItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("./theme-fonts/Poppins-LightItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("./theme-fonts/Poppins-Italic.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("./theme-fonts/Poppins-MediumItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("./theme-fonts/Poppins-SemiBoldItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("./theme-fonts/Poppins-BoldItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("./theme-fonts/Poppins-ExtraBoldItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("./theme-fonts/Poppins-BlackItalic.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("./theme-fonts/Poppins-Thin.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("./theme-fonts/Poppins-ExtraLight.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./theme-fonts/Poppins-Light.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./theme-fonts/Poppins-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./theme-fonts/Poppins-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./theme-fonts/Poppins-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./theme-fonts/Poppins-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("./theme-fonts/Poppins-ExtraBold.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./theme-fonts/Poppins-Black.ttf") format('truetype');
}

textarea {
  resize: vertical;
}

.d-none {
  display: none !important;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.icon-button {
  color: #aaa;
  cursor: pointer;
  transition: color 0.1s ease-out;
}

.icon-button:hover,
.icon-button:active {
  color: #212121;
}

.ui.table.table-themed {
  border-radius: var(--box-border-radius);
  box-shadow: var(--box-shadow);
}

.ui.table.table-themed thead tr:first-child>th:first-child {
  border-radius: var(--box-border-radius) 0 0;
}

.ui.table.table-themed thead tr:first-child>th:last-child {
  border-radius: 0 var(--box-border-radius) 0 0;
}