
:root {
  --color-primary: #ef432e;
  --color-primary-slight-dark: #d92611;
  --color-primary-dark: #aa1e0d;
  --color-secondary: #f8ca10;
  --color-info: #2f81a1;
  --color-info-light: #eef7fa;
  --color-success: #2fa13f;
  --color-success-slight-light: #78d885;
  --color-success-light: #eefaf0;
  --color-warning: #b69305;
  --color-warning-light: #fef9e5;
  --color-error: var(--color-primary-dark);
  --color-error-light: #fde5e2;
  --color-light-gray: #eaeaea;
  --color-light-gray-hover: #ddd;
  --color-light-gray-active: #d1d1d1;
  --color-message-text-gray: #676767;
  --color-layout-background: #f7f7f7;
  --color-text-gray: #bbb;
  --color-text-gray-dark: #909090;
  --color-new-green: var(--color-success-light);
  --button-secondary-hover-color: #fada5a;
  --button-secondary-active-color: #e8bb07;
  --navbar-sidemenu-mobile-bg-color: #7b1609;
  --loader-border-color: rgba(239, 67, 46, 0.3);
  --loader-top-border-color: var(--color-primary);
  --default-button-loading-color: rgba(248, 174, 164, 0.8);
  --color-text-black: #3c3c3c;
  --color-square-size: 32px;
  --color-square-margin: 3px;
  --box-border-radius: 15px;
  --box-border-radius-half: calc(var(--box-border-radius) / 2);
  --button-border-radius: 15px;
  --button-border-radius-half: calc(var(--button-border-radius) / 2);
  --border-color: var(--color-light-gray);
  --box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  --form-input-border-color-focus: #f6988d;
  --form-input-border: 2px solid var(--border-color);
  --basic-button-border-hover: 2px solid var(--color-light-gray-hover);
  --basic-button-border-active: 2px solid var(--color-light-gray-active);
  --card-divider-border: 1px solid rgba(34, 36, 38, 0.05);
  --color-square-border: 1px solid rgba(0, 0, 0, 0.25);
  --color-square-border-hover: 1px solid rgba(0, 0, 0, 0.15);
  --theme-font-family: Poppins, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  --navbar-active-color: #c2220f;
  --page-footer-color: #e8e8e8;
  --drop-file-zone-color: rgba(248, 202, 16, 0.6);
  --color-anchor: var(--color-primary-slight-dark);
  --color-anchor-hover: var(--color-primary);

  --landing-page-padding: 60px 20px;
}
